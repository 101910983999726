<template>
  <m-card :title="$t('shared.edit')">
    <address-editor ref="address" v-model="model" show-code />

    <template #actions>
      <page-footer edit @submit="submit" :loading="progressing" />
    </template>
  </m-card>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.addresses.getById(this.id).then((res) => {
        this.model = res
      })
    },
    submit() {
      this.$preSubmit('address').then((proceed) => {
        if (proceed) {
          this.$api.addresses
            .update(this.id, this.model)
            .then((res) => {
              this.model = res
              this.progressing = false
              this.$showSuccess(this.$t('addresses.addressUpdated'))
            })
            .catch((err) => {
              this.progressing = false
              this.$showError(err)
            })
        }
      })
    }
  }
}
</script>
